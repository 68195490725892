import styled from 'styled-components'
import SVG from "react-inlinesvg";

type Props = {
    onClick: any,
    icon: any,
    iconRight?: any,
    label: string
}

const DropdownButton = ({onClick, icon, iconRight, label}: Props) => {
    return (
        <Wrapper onClick={onClick}>
            <Icon src={icon}/>
            <Label>
                {label}
            </Label>
            <IconRight src={iconRight}/>
            
        </Wrapper>
    )
}

export default DropdownButton

const Wrapper = styled.div`
    height: 36px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    &:hover {
        background: ${props => props.theme.color.grey_50};
    }
    
`

const Label = styled.span`
    margin-left: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    flex: 1;
`

const Icon = styled(SVG)`
    margin-left: 15px;
    & path {
        fill: ${props => props.theme.color.grey_400}
    }
`
const IconRight = styled(SVG)`
    margin-right: 17px;
    & path {
        fill: ${props => props.theme.color.grey_400}
    }
`