import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import SVG from "react-inlinesvg";
import IconChevronRight from '../img/icons/icon_chevron_right_large.svg'
import IconChevronLeft from '../img/icons/icon_chevron_left_large.svg'
import IconClose from '../img/icons/icon_close.svg'
import { useEffect, useState } from 'react';

const SearchPopup = ({search, searchResult, onClose, startingElement}) => {
    const {t} = useTranslation()
    const [currentPageIndex, setCurrentPageIndex] = useState(0)

    useEffect(() => {
        setCurrentPageIndex(startingElement)
    }, [startingElement])


    const sendPostMessage = (type: string, number: number) => {
        window.readerFrame.postMessage({type, number}, "*")
    }

    const handlePageTurn = (direction) => {
        const pages = searchResult.content
        if(direction === 'back' && currentPageIndex !== 0){
            sendPostMessage('goToPage', pages[currentPageIndex - 1].itemId)
            setCurrentPageIndex(currentPageIndex - 1)
        }
        if(direction === 'next' && currentPageIndex !== searchResult.numberOfElements - 1){
            sendPostMessage('goToPage', pages[currentPageIndex + 1].itemId)
            setCurrentPageIndex(currentPageIndex + 1)
        }
        
    }

    return (

                <Wrapper>
                <ResultText>
                    {t('searchresults_for')}
                    <Searchterm>
                        {search}
                    </Searchterm>
                </ResultText>

                <InteractionWrapper>
                    <PageNumber>{`${t('hit')} ${currentPageIndex + 1}/${searchResult.numberOfElements}`}</PageNumber>
                    <Icon src={IconChevronLeft} onClick={() => handlePageTurn('back')}/>
                    <Icon src={IconChevronRight} onClick={() => handlePageTurn('next')}/>
                    <Close style={{marginLeft: "8px"}} src={IconClose} onClick={() => onClose()}/>
                </InteractionWrapper>
            </Wrapper>

    )
}

export default SearchPopup

const Wrapper = styled.div`
position: absolute;
right: 0;
left: 0;
margin: 0 auto;
bottom: 84px;
width: 450px;
height: 53px;
display: flex;
justify-content: space-around;
align-items: center;
background-color: ${props => props.theme.color.white};

    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
`

const ResultText = styled.span`


`
const Searchterm = styled.span`
    font-weight: bold;
`

const InteractionWrapper = styled.div`
    display: flex;
    align-items: center;
`

const PageNumber = styled.span`
    margin-right: 8px;
`

const Close = styled(SVG)`
    height: 14px;
    width: 14px;
    padding: 7px;
    border-radius: 6px;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }

    &:hover{
        background-color: ${props => props.theme.color.grey_50};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

    }
`
const Icon = styled(SVG)`
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }
    &:hover{
        background-color: ${props => props.theme.color.grey_50};
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);

    }
`