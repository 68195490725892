import {useState, useEffect} from 'react';
import {connect} from 'react-redux'
import _ from 'lodash'
import moment from 'moment-with-locales-es6'


import {loadEditionsFromXML} from './redux/actions/editionActions'
import {fetchCategories} from './redux/actions/categoryActions'
import {fetchSubscriptions} from './redux/actions/subscriptionActions'
import AppRouter from './router/AppRouter'
import { ThemeProvider } from 'styled-components';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'
import { loadFavorites } from './redux/actions/favoritesActions';
import editionIcon from './img/icons/icon_book.svg'
import i18next from 'i18next';

const customTheme = require(`./core/theme/${process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME :  'p1dev'}/theme`).theme


const App = ({loadSubscriptions, loadCategories, loadFavorites}) => {
  const defaultTheme = {
    color: {
      white: "#FFFFFF",
      grey_50: "#F8FAFC",
      grey_100: "#F1F5F9",
      grey_200: "#E2E8F0",
      grey_300: "#CBD5E1",
      grey_400: "#94A3B8",
      grey_500: "#64748B",
      grey_600: "#475569",
      grey_700: "#334155",
      grey_900: "#0F172A",
      red_50: "#FEF2F2",
      red_600: "#DC2626",
      destructive: "#DC2626",
      destructiveHover: "#c72020"
    },
    tracking: {
      type: '',
    },
    logoImage: "",
    header: {
      customer: "",
      title: "",
      lineHeader: false,
      image: ""
    },
    sections: [
      {
        id: 1,
        label: "Publikationen",
        label_single: "Publikation",
        type: "EDITION",
        icon: editionIcon
      },
    ],
    backend: "https://aps.publishing.one/",
    appName: "backend/",
    directory: "/",
    language: 'de',
    additionalLanguages: [],
    info: [],
    app: {

    },
    newsSlider: {

    },
    login: {
      type: 'none',
    }
  };
  const mergedTheme = _.merge({}, defaultTheme, customTheme)
  
  //add the default language to the language selection
  if(mergedTheme.additionalLanguages.length > 0){
    mergedTheme.additionalLanguages.unshift({language: mergedTheme.language, backend: mergedTheme.backend})
  }

  if(localStorage.getItem('language')){
    const language = JSON.parse(localStorage.getItem('language'))
    const useBackend = mergedTheme.additionalLanguages.find(item => item.language === language)

    if(useBackend && useBackend.backend){
      mergedTheme.backend = useBackend.backend
    }
  }

  const [theme, setTheme] = useState(mergedTheme)

  var myDynamicManifest = {
    "name": theme.app.title || theme.header.title,
    "short_name": theme.app.title || theme.header.title,
    "theme_color": theme.color.primary,
    "icons": [    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    }]
  }
  const stringManifest = JSON.stringify(myDynamicManifest);
  const blob = new Blob([stringManifest], {type: 'application/json'});
  const manifestURL = URL.createObjectURL(blob);
  
  useEffect(() => {
    //Mögliche Sprachen aktuell: de, en
    if(localStorage.getItem('language')){
      const languageItem = JSON.parse(localStorage.getItem('language'))
      i18next.changeLanguage(languageItem)
    } else if(theme.additionalLanguages.find(item => navigator.language.startsWith(item.language))){
      const navLanguage = theme.additionalLanguages.find(item => navigator.language.includes(item.language))
      i18next.changeLanguage(navLanguage.language)
    } else if(theme.language !== "de"){
      i18next.changeLanguage(theme.language)
    }
    
    document.title = (i18next.language === theme.language) || theme.additionalLanguages.length === 0 ? theme.header.title : theme.additionalLanguages.find(item => item.language === i18next.language).title
    document.querySelector('#manifest-placeholder').setAttribute('href', manifestURL);
    moment.locale('de')
    loadCategories(theme.backend, theme.appName)
    loadSubscriptions(theme.backend, theme.appName)
    loadFavorites()
    

  }, [loadCategories])



  if(theme.tracking && theme.tracking.type === 'Matomo'){
    const matomoInstance = createInstance({
      urlBase: theme.tracking.urlBase,
      siteId: theme.tracking.siteId,
      configurations: {
        disableCookies: true,
      }
    })

    return (
      <MatomoProvider value={matomoInstance}>
        <ThemeProvider theme={theme}>
          <AppRouter/>
        </ThemeProvider>
      </MatomoProvider>
    );
  }
  return (
      <ThemeProvider theme={theme}>
        <AppRouter/>
      </ThemeProvider>
  );



}

const mapStateToProps = (state, props) => {
  return {
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadEditions: () => dispatch(loadEditionsFromXML()),
  loadSubscriptions: (backend, appName) => dispatch(fetchSubscriptions(backend, appName)),
  loadCategories: (backend, appName) => dispatch(fetchCategories(backend, appName)),
  loadFavorites: () => dispatch(loadFavorites()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
