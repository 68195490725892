import {useContext} from 'react'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components';
import {NavLink, useLocation} from 'react-router-dom'
import { useHistory } from 'react-router-dom';
interface Props {
    id: string
    name: string
    categories: any
}

type StyledLinkProps = {
    active: boolean
}


const CategoryListEntry = ({id, name, categories}: Props) => {
    const location = useLocation()
    const themeContext = useContext(ThemeContext);
    const history = useHistory()

    const findActiveCategory = () => {
        if(location.pathname.includes('category') && !location.pathname.includes('category/0')){
            const categoryId = location.pathname.match(/\d+/)
            if(categoryId){
                return getTopCategory(categoryId[0]) === id
            } else {
                return false
            }
        }
        return false
    }

    const getTopCategory = (id) => {
            const currentCategory = categories.find(category => {            
                return category.id === id
            })

            if(!currentCategory){
                
                history.push('/no_such_category')
                return
            }

            if((currentCategory.parent === null || themeContext.sections.some(section => section.id === currentCategory.parent))){
                return currentCategory.id
            }
            const parentCategory = categories.find(category => category.id === currentCategory.parent)
            if(parentCategory.parent === null || themeContext.sections.some(section => section.id ===parentCategory.parent)){
                return parentCategory.id
            } else {
                getTopCategory(parentCategory.id)
            }
        
        
    }

    return (
            <StyledLink active={findActiveCategory()}  exact to={`/category/${id}`}>
                <Label>
                {name}
                </Label>
            </StyledLink>

    )
}

export default CategoryListEntry

const StyledLink = styled(NavLink)<StyledLinkProps>`
    box-sizing: border-box;
    width: 222px;
    margin-bottom: 4px;
    border-radius: 6px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    background: ${props => props.active ? props.theme.color.grey_100 : 'white'};

    &:hover {
        background: ${props => props.theme.color.grey_50};
        
    }
`

const Label = styled.label`
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_600};
    cursor: pointer;
`