import styled from 'styled-components'
type Props = {
    children?: any,
    header?: boolean,
    active?: boolean
}
const Dropdown = ({children, active, header}: Props) => {

    return (
        <>
            { active &&
                <Wrapper header={header}>
                    {children}
                </Wrapper>
            }
        </>
    )
}

export default Dropdown

const Wrapper = styled.div<Props>`
    position: absolute;
    top: ${props => props.header ? '72px' : '44px'};
    right: ${props => props.header ? '60px' : '0'};
    min-width: 225px;
    background-color: white;
    padding: 4px;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    /* Shadow / XL */

    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
`