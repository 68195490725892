import styled from 'styled-components'
import SVG from "react-inlinesvg";
import {device} from "../media"

interface Props {
    icon?: any,
    title?: string,
    primary?: boolean,
    tertiary?: boolean,
    active?: boolean,
    onClick?: any,
    children?: any
}

const Button = ({children, title, onClick, icon, primary, tertiary, active}: Props) => {

    return (
        <Wrapper>
            <ButtonElement primary={primary} tertiary={tertiary} active={active} onClick={onClick}>
                { icon && 
                    <StyledSVG src={icon}/>}
                {
                    icon && title &&
                    <Seperator/>
                }
                <Title primary={primary}>{title}</Title>
                
                
            </ButtonElement>
                {children}
        </Wrapper>
    )
}

export default Button

const Wrapper = styled.div`
    position: relative;
`

const ButtonElement = styled.button<Props>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: ${props => props.tertiary ? '8px' : '11px 16px'};
    margin: 0 8px;
    height: ${props => props.tertiary ? '32px' : '36px'};;
    box-shadow: ${props => props.tertiary ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.05)'};
    border-radius: 8px;

    border: ${props => props.tertiary ? 'none' : '1px solid' + props.theme.color.grey_200};
    background-color: ${props => (props.primary ? props.theme.color.primary : '#FFFFFF')};
    cursor:pointer;

    & path {
        fill: ${props => props.tertiary ? props.theme.color.grey_500 : props.theme.color.grey_400};
    }

    &:hover{
        background-color: ${props => (props.primary ? props.theme.color.primaryHover : props.theme.color.grey_50)};
        box-shadow: ${props => props.tertiary ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)'};
    }

    @media ${device.mobile}{
        border: none;
        box-shadow: none;
        padding: 0;
        width: 24px;

        & path {
            fill: ${props => props.theme.color.grey_500};
    }
    }


`


const StyledSVG = styled(SVG)`

`

const Title = styled.span<Props>`
    color: ${props =>  props.primary ? '#FFFFFF' : props.theme.color.grey_900};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;

    @media ${device.mobile}{
        display: none;
    }
`

const Seperator = styled.span`
    width: 8px;
`