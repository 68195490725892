import {connect} from 'react-redux'
import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios'
import EditionListShort from '../components/EditionListShort';
import ContentBody from '../components/ContentBody'
import { ThemeContext } from 'styled-components';
import ContentHeadline from '../components/ContentHeadline';
import { useTracking } from '../hooks/useTracking';

function EditionsPage({match}) {
  const [category, setCategory] = useState([])
  const themeContext = useContext(ThemeContext);
  const {t} = useTranslation()
  const history = useHistory()
  const {trackPageVisit} = useTracking()

  // Track page view
  useEffect(() => {
    if(themeContext.sections.length > 1){
      history.replace("category/"+ themeContext.sections[0].id)
    }
      trackPageVisit({documentTitle: `Start`})
  }, [])


  
  useEffect(() => {
      setCategory([])
      axios.get(`${themeContext.backend}${themeContext.appName}category/0`)
      .then((response) => {
          setCategory(response.data)
      })
  }, [])

  return (
      <ContentBody>
        <ContentHeadline text={t('start')}/>
        {
          //@ts-ignore
          category.categories && category.categories.map((category, index) => {
            return <EditionListShort key={category.id} category={category} name={index === 0 ? t("newest_editions") : category.name}/>
          }
            
          )
        }
        

      </ContentBody>
  );
}

const mapStateToProps = (state, props) => {
  return {
    editions: state.editions.content,
    categories: state.categories
  }
}

export default connect(mapStateToProps)(EditionsPage);
