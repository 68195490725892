import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ContentBody from '../components/ContentBody';
import EmptyContent from '../components/EmptyContent';
import { useTracking } from '../hooks/useTracking';

const NotFound = () => {
    const {t} = useTranslation()
    
    const {trackPageVisit} = useTracking()

    // Track page view
    useEffect(() => {
        trackPageVisit({documentTitle: `Not Found`})
    }, [])
    
    return (
        <ContentBody>
            <EmptyContent title={t('not_found_title')} description={t('not_found_description')} />
        </ContentBody>  
    )
}

export default NotFound