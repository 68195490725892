import {useEffect, useRef} from 'react'
import styled from 'styled-components'
import SVG from "react-inlinesvg";
import {device} from "../media"

import PopupMenu from '../reader/PopupMenu'

type Props = {
    src: string,
    onClick?: any,
    disabled?: boolean,
    popup?: boolean,
    children?: any,
    mobilehidden?: boolean
    popupActive?: boolean 
    setPopupActive?: any

}

type SVGProps = {
    disabled?: boolean
    mobilehidden?: string
}


const ToolbarButton = ({src, onClick, disabled, popup, children, mobilehidden, popupActive, setPopupActive}: Props) => {
    const ref = useRef(null)

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {

                if (ref && ref.current && popup && !ref.current.contains(event.target)) {                
                        // @ts-ignore
                        setPopupActive(false)
                }
            

        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = () => {
        if(disabled){
            return
        }
        if(popup){
            setPopupActive(!popupActive)
        } else {
            onClick()
        }
    }


    

    return (
        // @ts-ignore
        <Wrapper ref={ref}>
            <Icon src={src} disabled={disabled} onClick={handleClick} mobilehidden={mobilehidden && mobilehidden.toString()}/>
            <PopupMenu active={popupActive}>
                {
                    popup && children
                }
            </PopupMenu>
        </Wrapper>
    )
}

export default ToolbarButton

const Wrapper = styled.span`
    position: relative;
    @media ${device.mobile}{
        position: static;
    }
`

const Icon = styled(SVG)<SVGProps>`
    display: inline;
    cursor: ${props => props.disabled ? 'default' : 'pointer'};
    border-radius: 8px;
    padding: 8px;
    & path {
        fill: ${props => props.disabled ? props.theme.color.grey_300 : props.theme.color.grey_500};
    }

    &:hover{
        background-color: ${props => props.disabled ? 'none' : props.theme.color.grey_50};
    }
    @media ${device.mobile}{
        display: ${props => props.mobilehidden === "true" ? 'none' : 'inline'}
    }
`