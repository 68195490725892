import {useContext} from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import { ThemeContext } from 'styled-components';
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module'


export const useTracking = () => {
    const { trackPageView, pushInstruction } = useMatomo()
    const themeContext = useContext(ThemeContext); 

    const trackPageVisit = (title) => {
        switch (themeContext.tracking.type) {
            case 'Matomo':
                trackPageView({documentTitle: title})
                break;
            case 'GUA':
                ReactGA.send({ hitType: "pageview", title})
                break;
            case 'GA4':
                ReactGA.send({ hitType: "pageview", title})
                break;
            default:
                break;
        }
        
    }

    const customCommand = (options) => {
        switch (themeContext.tracking.type) {
            case "GA4":
                ReactGA.ga("set", "content_group" ,options.dimensions.dimension1)
                trackPageVisit(options.title)
                ReactGA.ga("set", "content_group" , "")
                break;
            case 'Matomo':
                pushInstruction(options.type, options.title, options.dimensions)
                break;
            default:
                trackPageVisit(options.title)
                break;
        }
        
    }

    const initializeTracking = () => {
        switch (themeContext.tracking.type) {
            case 'GTM':
                console.log('GTM')
                const tagManagerArgs = {
                    gtmId: themeContext.tracking.id
                }
                TagManager.initialize(tagManagerArgs)
                break;
            case 'GUA':
                ReactGA.initialize(themeContext.tracking.id);
                break;
            case 'GA4':
                ReactGA.initialize(themeContext.tracking.id);
                break;
            default:
                break;
        }

    }
    return {trackPageVisit, customCommand, initializeTracking}
}   