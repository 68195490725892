import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import {device} from "../media"
import SVG from "react-inlinesvg";
interface Props {
    link: string,
    label: string,
    icon: any,
}

const NavigationItem = ({link, label, icon}: Props) => {
    return (
            <StyledLink exact to={`/${link}`}>
                <Icon src={icon}/>
                <Label>
                    {label}
                </Label>
            </StyledLink>
    )
}

export default NavigationItem

const StyledLink = styled(NavLink)`
    box-sizing: border-box;
    height: 38px;
    width: 222px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 6px;
    margin: 0;
    margin-bottom: 4px;

    &:hover {
        background: ${props => props.theme.color.grey_50};
    }

    &.active {
        span {
            color: ${props => props.theme.color.grey_900};
        }
        & path {
        fill: ${props => props.theme.color.grey_500};
        }

        background: ${props => props.theme.color.grey_100};
        &:hover {
        background: ${props => props.theme.color.grey_100};
    }

    }
    @media ${device.mobile}{
        flex-direction: column;
        height: 100%;
        justify-content: center;
        align-items: center;
        margin: 0;
    }
`

const Icon = styled(SVG)`
`

const Label = styled.span`
    font-weight: 500;
    font-size: 14px;
    margin-left: 16px;
    color: ${props => props.theme.color.grey_600};
    padding-top: 2px;
    @media ${device.mobile}{
        font-size: 10px;
        margin: 0;
        margin-top: 4px;
    }
`