import {useContext, useEffect, useRef} from 'react'
import styled from 'styled-components'
import { ThemeContext } from 'styled-components';

const IFrameMediaPlayer = ({src, query, username=null, password=null}) => {
    const themeContext = useContext(ThemeContext);
    const ref = useRef<HTMLIFrameElement>(null)

    const handleMouseDown = _e => {
        const event = new Event('mousedown')
        document.dispatchEvent(event)
    }

    useEffect(() => {
        const controller = new AbortController()
        ref?.current?.contentWindow.addEventListener(
            "mousedown",
            handleMouseDown,
            {signal: controller.signal} as any)

        return () => controller.abort()
    })

    return(
        <IFrame ref={ref} src={src + `/index.html?${query ? query + "&" : ""}piosk=true&primaryColor=`  + themeContext.color.primary.substr(1) + "&hoverColor=" + themeContext.color.primaryHover.substr(1) + `${username ? "&username=" + encodeURI(username) : ""}` + `${password ? "&password=" + encodeURI(password) : ""}`}/>
    )
    
}

export default IFrameMediaPlayer

const IFrame = styled.iframe`
    height: 100%;
    width: 100%;
    border: none;
    margin-top: 0px;
`