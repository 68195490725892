const defaultState = {content: [], current: ''}
const categoriesReducer = (state = defaultState, action) => {
    switch (action.type) {
      case 'LOAD_EDITIONS':
        return action.editions
      case 'CURRENT':
        return {...state, current: action.current}
      case 'EMPTY_EDITIONS':
        return defaultState
      default:
        return state
    }
  }
  
  export default categoriesReducer