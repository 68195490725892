import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import SVG from "react-inlinesvg";

import IconArrowLeft from '../img/icons/icon_arrow_left.svg'
import IconArrowRight from '../img/icons/icon_arrow_right.svg'
import {device} from "../media"

type PageNumberProps = {
    active?: boolean
}

type ButtonProps = {
    active: boolean
}

const Pagination = ({pages, currentPage, setCurrentPage}) => {
    const {t} = useTranslation()

    const placeholder = '...'

    const generateNumbers = () => {
        var numbers = []
        for(var i = 0; i < pages; i++) {
            if(i < 2 || i > pages - 3 || (i >= currentPage - 2 && i <= currentPage )){
                numbers.push(i + 1)
            } else {
                if(numbers[numbers.length - 1] !== placeholder){
                    numbers.push(placeholder)
                }
            }
        }
        return numbers
    }

    const handlePageChange = (number) => {
        if(number === placeholder){
            return
        }
        setCurrentPage(number)
    }
    
    return (
        <Wrapper>
            <Button active={currentPage > 1} onClick={() => currentPage > 1 ? setCurrentPage(currentPage - 1) : null}>
                <Icon src={IconArrowLeft}/>
                {t('previous')}
            </Button>
            <NumberWrapper>
                {

                    generateNumbers().map((number, index) => (
                        <PageNumber key={index} onClick={() => handlePageChange(number)} active={currentPage === number}>
                            {number}
                        </PageNumber>
                    ))
                }
            </NumberWrapper>
            <Button active={currentPage < pages} onClick={() => currentPage < pages ? setCurrentPage(currentPage + 1) : null}>
                {t('next')}
                <Icon src={IconArrowRight}/>
            </Button>
        </Wrapper>
    )
}

export default Pagination

const Wrapper = styled.div`
    width: 100%;
    border-top: 1px solid ${props => props.theme.color.grey_200};
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    height: 36px;
    align-items: flex-end;
`

const Button = styled.div<ButtonProps>`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.theme.color.grey_500};
    display: flex;
    align-items: center;
    cursor: ${props => props.active && 'pointer'};
    opacity: ${props => props.active ? 1 : 0.4};
    &:hover {
        color: ${props => props.active && props.theme.color.grey_900};
        & path {
            fill: ${props => props.active && props.theme.color.grey_900};
        }
    }
`

const Icon = styled(SVG)`
    width: 16px;
    margin: 0 14px;
    & path {
        fill: ${props => props.theme.color.grey_400};
    }
`

const NumberWrapper = styled.div`
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @media ${device.mobile}{
        display: none
    }
`

const PageNumber = styled.span<PageNumberProps>`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    width: 42px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${props => props.active ? props.theme.color.primary : props.theme.color.grey_500};
    border-top: ${props => props.active ? '2px solid ' + props.theme.color.primary : 'none'};   
    cursor: pointer; 

    &:hover {
        color: ${props => props.active ? props.theme.color.primary : props.theme.color.grey_900};
    }
`