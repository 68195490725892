import styled from 'styled-components'
import SVG from "react-inlinesvg";

import IconError from "../img/icons/icon_error.svg"

const ErrorBox = ({text}) => {
    return (
        <Wrapper>
            <IconWrapper>
                <Icon src={IconError}/>
            </IconWrapper>
            
            <Text>
                {text}
            </Text>
            
        </Wrapper>
    )
}

export default ErrorBox

const Wrapper = styled.div`
    display: flex;
    background: ${props => props.theme.color.red_50};
    border-radius: 8px;
    padding: 12px;
    margin-top: 24px;

`
const IconWrapper = styled.div`
    width: 14px;
    margin-right: 12px;
    margin-top: 3px;
`
const Icon = styled(SVG)`
    

`

const Text = styled.span`
    color: ${props => props.theme.color.red_600};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
`