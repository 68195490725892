import {useState, useEffect, useRef, useContext} from 'react'
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import styled from 'styled-components'
import SVG from "react-inlinesvg";
import IconLanguage from "../img/icons/icon_language.svg"
import Dropdown from "./Dropdown"
import i18next from 'i18next';

const LanguageSelectionButton = () => {
    const [dropdownActive, setDropdownActive] = useState(false)
    const ref = useRef(null)
    const {t} = useTranslation()
    const themeContext = useContext(ThemeContext);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref && ref.current && !ref.current.contains(event.target)) {
                    // @ts-ignore
                    setDropdownActive(false)

                
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const setLanguage = (languageItem) => {
        //i18next.changeLanguage(languageItem)
        localStorage.setItem('language', JSON.stringify(languageItem))
        window.location.reload();
    }

    return(
        <>
        <Wrapper ref={ref} onClick={() => setDropdownActive(!dropdownActive)}>
            <IconWrapper active={dropdownActive}>
                <Icon active={dropdownActive} src={IconLanguage}/>

                <LanguageShort active={dropdownActive}>{i18next.language}</LanguageShort>
            </IconWrapper>


            <Dropdown active={dropdownActive}>
                {
                    themeContext.additionalLanguages.length > 0 && themeContext.additionalLanguages.map((item, index) => (
                        <DropdownLink key={index} target="blank" active={i18next.language === item.language} onClick={() => setLanguage(item.language)}>{t(item.language)}</DropdownLink>
                    ))
                }
            </Dropdown>
            

        </Wrapper>
        </>
    )
}

export default LanguageSelectionButton

type ButtonProps = {
    active: boolean
}

const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
`

const IconWrapper = styled.span<ButtonProps>`
    display: flex;
    align-items: center;
    opacity: ${props => props.active ? 0.65 : 1};
    &:hover {
        opacity: 0.65;
    }
`

const Icon = styled(SVG)<ButtonProps>`    
    &:last-of-type {
        margin-left: 9px;
    }

`

const LanguageShort = styled.span<ButtonProps>`
    text-transform: uppercase;
    margin-left: 6px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.color.grey_500};


`

const DropdownLink = styled.a<ButtonProps>`
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-weight: ${props => props.active ? 600 : 400};
    color: ${props => props.active ? props.theme.color.grey_900 : props.theme.color.grey_700};
    background-color: ${props => props.active ? props.theme.color.grey_100 : props.theme.color.white};
    &:hover {
        background-color: ${props => props.theme.color.grey_50};
    }
`