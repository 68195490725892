import styled from 'styled-components'
import SVG from "react-inlinesvg";

type Props = {
    id: string,
    thumbnail: any,
    icon: any,
    title: string,
    onClick: any,
    children?: any,
    active?: boolean,
    activeIcon?: any
}

type ActiveProps = {
    active?: boolean
}

const DropdownItem = ({id, thumbnail, icon, title, onClick, children, active, activeIcon}: Props) => {
    return (
        <Wrapper id={id} onClick={onClick} active={active}>
            <Thumbnail src={thumbnail} alt={`Bild "${title}"`}/>
            {children}
            <TextWrapper>
                <Title>{title}</Title>
            </TextWrapper>
            <Icon active={active} src={active ? activeIcon : icon}/>
        </Wrapper>
    )
}

export default DropdownItem

const Wrapper = styled.div<ActiveProps>`
    display: flex;
    padding: 8px 12px;
    align-items: center;
    cursor: pointer;
    border-radius: 6px;
    background: ${props => props.active ? props.theme.color.grey_100 : "none"};
    &:hover {
        background: ${props => props.active ? props.theme.color.grey_100 : props.theme.color.grey_50};
    }
`

const Thumbnail = styled.img`
    height: 40px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
`

const TextWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin-left: 12px;
`

const Title = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
    
`

const Icon = styled(SVG)<ActiveProps>`
    width: 16px;
    & path {
    fill: ${props => props.active ? props.theme.color.primary : props.theme.color.grey_400};
    }
`