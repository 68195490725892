import axios from 'axios'
import convert from 'xml-js'

export const loginSuccess = (user) => ({
  type: 'LOGIN',
  user
})

export const logout = () => ({
  type: 'LOGOUT'
})

export const loginError = () => ({
  type: 'LOGIN_ERROR'
})

/* export const loginBackend = (backend, appName, username, password) => {
  return(dispatch) => {
    return axios.post(`${backend}${appName}login?username=${encodeURI(username)}&password=${encodeURI(password)}`)
    .then((response) => {
      const login: any = convert.xml2js(response.data, {
        compact: true,
        alwaysArray: true
        })
        console.log(login.login[0].success[0]._text[0])
        if(login.login[0].success[0]._text[0] === "true"){
            dispatch(login({loggedIn: true, username: username, password: password}))
        } else {
          dispatch(loginError())
        }
      
    })
  }
} */

export const login = (backend, appName, username, password) => {
  return(dispatch) => {
    return axios.post(`${backend}${appName}login`, null, {params:{
      username: username,
      password: password
    }})
    .then((response) => {
      const login: any = convert.xml2js(response.data, {
        compact: true,
        alwaysArray: true
        })
        const permissions = []
        if(login.login[0].permissions){
          if(login.login[0].permissions[0].permission){
            login.login[0].permissions[0].permission.map(permission => {
              permissions.push(permission._text[0])
            })
          }

        }
        if(login.login[0].success[0]._text[0] === "true"){
            dispatch(loginSuccess({loggedIn: true, username: username, password: password, permissions: permissions}))
        } else {
          dispatch(loginError())
        }
      
    })
    .catch(error => {
      console.log('error', error)
      dispatch(loginError())
    })
  }
}