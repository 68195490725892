import axios from 'axios'

export const loadSubscriptions = (subscriptions) => ({
  type: 'LOAD_SUBSCRIPTIONS',
  subscriptions
})

export const fetchSubscriptions = (backend, appName) => {
  return(dispatch) => {
    return axios.get(`${backend}${appName}subscriptions`)
    .then((response) => {
      dispatch(loadSubscriptions(response.data))
    })
  }
}