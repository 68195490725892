import { useContext, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components'
import {connect} from 'react-redux'
import { useTranslation } from 'react-i18next';

import NavigationItem from '../components/NavigationItem'
import CategoryList from '../components/CategoryList'
import startIcon from '../img/icons/icon_start.svg'
import favoritesIcon from '../img/icons/icon_favorites.svg'
import searchIcon from '../img/icons/icon_search.svg'
import {device} from "../media"
import {updateSection} from "../redux/actions/currentActions"

const Navigation = ({categories, currentSection, updateSection}) => {
    const themeContext = useContext(ThemeContext);
    const {t} = useTranslation()
    useEffect(() => {
        if(themeContext.sections.length <= 1 && categories.length > 0) {
            if(categories[0].id === "1"){
                updateSection("1")
            }
        }
    }, [categories])
    return (
        <Wrapper>
            {
                themeContext.sections.length < 2 &&
                <NavigationItem 
                    link=""
                    label={t('start')}
                    icon={startIcon}/>
            }

            {
                themeContext.sections.length > 1 && themeContext.sections.map((section) => (
                    <NavigationItem
                        link={"category/" + section.id}
                        label={section.label}
                        icon={section.icon}/>
                ))
            }
            <NavigationItem 
                link="favorites"
                label={t('favorites')}
                icon={favoritesIcon}/>
            <CategoryList
                categories={categories}
                currentSection={currentSection}/>

        </Wrapper>
    )
}

const mapStateToProps = (state, props) => {
    return {
      categories: state.categories,
      currentSection: state.current.section
    }
  }

  const mapDispatchToProps = (dispatch) => ({
    updateSection: (section) => dispatch(updateSection(section)),
  })

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-right: solid 1px ${props => props.theme.color.grey_100};
    height: 100%;
    padding-right: 12px;
    @media ${device.mobile}{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        height: 57px;
        background-color: ${props => props.theme.color.white};
        z-index: 100;
    }
`