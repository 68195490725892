import {useContext} from 'react'
import styled from 'styled-components'
import {Link, useLocation} from 'react-router-dom'
import {device} from "../media"
import { ThemeContext } from 'styled-components';
interface Props {
    edition: any
}

const Card = ({edition} : Props) => {
    const location = useLocation()
    const themeContext = useContext(ThemeContext);

    return (
        
        edition.path.startsWith('https://') ?
        <StyledExternalLink href={edition.path} target="blank">
            <Image 
                src={edition.cover.startsWith("https://") ? edition.cover : themeContext.backend + edition.path + "/" + edition.cover}/>
            <Label>
                {edition.title}
            </Label>
        </StyledExternalLink>
        :
        <StyledLink to={{pathname: `/detail/${edition.id}`, state: { from: location.pathname }}}>
            <Image 
                src={edition.cover.startsWith("https://") ? edition.cover : themeContext.backend + edition.path + "/" + edition.cover}/>
            <Label>
                {edition.title}
            </Label>
        </StyledLink>
    )
}

export default Card

const StyledLink = styled(Link)`
    box-sizing: border-box;
    min-width: 150px; 
    max-width: 215px;
    border-radius: 8px;
    padding: 16px;
    margin: 12px;
    background: ${props => props.theme.color.grey_50};
    &:hover {
        background: ${props => props.theme.color.grey_100};
        
    }
    @media ${device.tablet}{
        width: 160px;
    }
    @media ${device.mobile}{
        margin: 8px;
        padding: 0;
        background: ${props => props.theme.color.white};
        width: 150px;

        &:hover {
            background: ${props => props.theme.color.white};
        }
    }
`
 
 const StyledExternalLink = styled.a`
    box-sizing: border-box;
    min-width: 150px; 
    max-width: 215px;
    border-radius: 8px;
    padding: 16px;
    margin: 12px;
    background: ${props => props.theme.color.grey_50};
    &:hover {
        background: ${props => props.theme.color.grey_100};
        
    }
    @media ${device.tablet}{
        width: 160px;
    }
    @media ${device.mobile}{
        margin: 8px;
        padding: 0;
        background: ${props => props.theme.color.white};
        width: 150px;

        &:hover {
            background: ${props => props.theme.color.white};
        }
    }
`
const Image = styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    border: 0.5px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    @media ${device.mobile}{
        margin-bottom: 12px;
    }
    &:hover {
        box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    }
`

const Label = styled.label`
    cursor: pointer;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 125%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @media ${device.mobile}{
        font-size: 12px;

    }
`