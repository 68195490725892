import { createStore, combineReducers, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'

import editionsReducer from './reducers/editionsReducer'
import categoriesReducer from './reducers/categoriesReducer'
import favoritesReducer from './reducers/favoritesReducer'
import subscriptionsReducer from './reducers/subscriptionsReducer'
import userReducer from './reducers/userReducer'
import currentReducer from './reducers/currentReducer'

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose || compose

export default () => {
  const store = createStore(
    combineReducers({
      editions: editionsReducer,
      categories: categoriesReducer,
      favorites: favoritesReducer,
      subscriptions: subscriptionsReducer,
      user: userReducer,
      current: currentReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  )

  return store
}