

const currentSection = (section) => ({
  type: 'CURRENT_SECTION',
  section
})

export const updateSection = (section) => {
  return(dispatch) => {
    dispatch(currentSection(section))
  }
}