import {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {device} from "../media"

import { useTranslation } from 'react-i18next';

interface DescriptionProps {
    expanded?: boolean
}

interface Props {
    tableItems: {title: string, length?: string}[]
    halfsize?: boolean
    metadataLabel? : string
}

interface WrapperProps {
    halfsize?: boolean
}

const ExpandableTable = ({tableItems, halfsize, metadataLabel}: Props) => {
    const [expandedTable, setExpandedTable] = useState(false)
    const [maxDescriptionHeight, setMaxDescriptionHeight] = useState(0)
    const {t} = useTranslation()
    const descRef = useRef(null)
console.log(tableItems)
    useEffect(() => {
        if(descRef.current && descRef.current.clientHeight > maxDescriptionHeight){
            setMaxDescriptionHeight(descRef.current.clientHeight)
        }
    }, [maxDescriptionHeight])

    return (
        <Wrapper halfsize = {halfsize}>
            <TextWrapper>
            {
                metadataLabel &&
                <Label>
                    {metadataLabel}
                </Label>
            }
            {
                tableItems.length > 0 && 
                <Table>
                    <TableBody expanded={expandedTable}>
                    {
                        tableItems.map((data, index) => (
                            ((!expandedTable && index < 4) || (expandedTable)) && 
                                <Row>
                                    <TableData width="100%" >{data.title}</TableData>
                                    <TableData width="15%" >{data.length}</TableData>
                                </Row>
                        )

                            

                        

                        )  
                    }
                    </TableBody>
                </Table>
            }
            </TextWrapper>
                {
                    tableItems.length > 5 && 
                    <ToggleExpand onClick={() => setExpandedTable(!expandedTable)}>
                        {
                            expandedTable ?
                            t('less')
                            :
                            t('more')
                        }
                    </ToggleExpand>

                }
            
        </Wrapper>
    )
}

export default ExpandableTable

const Wrapper = styled.div<WrapperProps>`
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: ${props => props.halfsize ? "222px" : "100%"};
    @media ${device.mobile}{
        width: ${props => props.halfsize ? "162px" : "100%"};
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`

const Table = styled.table<DescriptionProps>`
    width: 100%;
    border-collapse: collapse;
`

const TableBody = styled.tbody<DescriptionProps>`
    max-height: ${props => props.expanded ? '100%' : '99px'}; 
    transition: max-height 0.5s ${props => props.expanded ? 'ease-in' : 'ease-out'}; 
    color: ${props => props.theme.color.grey_700};
    font-size: 14px;
    line-height: 150%;
`

const Row = styled.tr`
    &:nth-child(even) {
        background-color: #F8FAFC;
    }
`

const TableData = styled.td`
    padding: 2px 8px;
`

const ToggleExpand = styled.span`
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    z-index: 2;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-self: flex-end;
    padding-top: 12px;
    @media ${device.tablet}{
        margin-right: 14px;
    }
    &:hover {
        color: ${props => props.theme.color.primaryHover};
    }
`

const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 8px;
    
`