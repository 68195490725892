import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import Thumbnail from '../reader/Thumbnail'

const PagePicker = ({currentEdition, pages, doublePages, onClick, setPopupActive}) => {
    const {t} = useTranslation()
    
    const handleClick = (id) => {
        onClick(id)
        setPopupActive(false)
    }

    return (
        <Wrapper>
            {
                pages.map((page, index) => (
                    <>
                    {
                        doublePages ?
                            (index === 0 || index % 2 === 1) &&
                            <PageItem onClick={() => handleClick(page.id + 1)}>
                                <DoubleWrapper>
                                    <Thumbnail src={`${currentEdition}/preview/thumb/${page.id + 1}.jpg`} alt={`${t('page')} ${page.number}`}/>
                                    {
                                        doublePages && index > 0 && pages[index + 1] &&
                                        <Thumbnail src={`${currentEdition}/preview/thumb/${page.id + 2}.jpg`} alt={`${t('page')} ${pages[index + 1].number}`}/>
                                    }
                                </DoubleWrapper>
                                <DoubleWrapper>
                                    <Text>
                                        {page.number}
                                    </Text>
                                    {
                                            doublePages && index > 0 && pages[index + 1] &&
                                            <>
                                                <Seperator/>
                                                <Text>
                                                    {pages[index + 1].number}
                                                </Text>
                                            </>
                                        }
                                </DoubleWrapper>
                            </PageItem>
                            :
                            <PageItem onClick={() => handleClick(page.id + 1)}>
                                <DoubleWrapper>
                                    <Thumbnail src={`${currentEdition}/preview/thumb/${page.id + 1}.jpg`}/>
                                </DoubleWrapper>
                                <DoubleWrapper>
                                    <Text>
                                        {page.number}
                                    </Text>
                                </DoubleWrapper>
                            </PageItem>

                    }
                    </>
                    
                ))
            }
            
        </Wrapper>
    )
}

export default PagePicker

const Wrapper = styled.div`
    display: flex;
    max-width: 900px;
    overflow-x: auto;
    padding: 16px 0;
`

const PageItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    border-radius: 8px;
    padding: 4px 8px;
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.color.grey_100};
    }
`

const DoubleWrapper = styled.div`
    display: flex;
    margin: 4px 0;
`



const Text = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const Seperator = styled.span`
    height: 18px;
    width: 1px;
    background: ${props => props.theme.color.grey_400};
    margin: 0px 10px;
`