const userReducer = (state = {}, action) => {
    switch (action.type) {
      case 'LOGIN':
        return action.user
      case 'LOGOUT':
        return {}
      case 'LOGIN_ERROR':
        return {error: true}
      default:
        return state
    }
  }
  
  export default userReducer