import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import styled from 'styled-components'
import {device} from "../media"

const TeaserImage = () => {
    const themeContext = useContext(ThemeContext);
    return (
        <Wrapper>
            {
                !!themeContext.header.image ?
                <>
                    <ImageContent src={themeContext.header.image}/>
                    <ImageTextWrapper>
                        <ImageTextContent>
                            <ImageText>
                                {themeContext.header.title}
                            </ImageText>
                        </ImageTextContent>
                    </ImageTextWrapper>

                </>
                :

                 !themeContext.header.lineHeader ?

                <TextContent>
                    {
                    themeContext.header.customer &&
                        <Customer>
                            {themeContext.header.customer}
                        </Customer>
                    }
                    {
                        themeContext.header.title &&
                        <Titel>
                            {themeContext.header.title}
                        </Titel>
                    }
    
                </TextContent>

                :

                <LineHeader/>
            }
            
        </Wrapper>
    )
}

export default TeaserImage

const Wrapper = styled.div`
    width: 100%;
    background: ${props => props.theme.header.bannerColor || props.theme.color.primary};
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    position: relative;

`

const LineHeader = styled.span`
    width: 100%;
    background: ${props => props.theme.color.primary};
    height: 2px;
`

const TextContent = styled.div`
    width: 960px;
    display: flex;
    flex-direction: column;
    margin: 16px 0;

    justify-content: center;
    @media ${device.tablet}{
        width: 820px;
        margin-left: 12px;
    }
    @media ${device.mobile}{
        margin-left: 12px;
    }

`

const ImageContent = styled.img`
    width: 100%;
    height: 98px;
    object-fit: cover;
    @media ${device.mobile}{
        height: 50px;
    }
`

const ImageTextWrapper = styled.span`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    justify-content: center;
`

const ImageTextContent = styled.span`
    width: 960px;
    display: flex;
    align-items: center;
    @media ${device.tablet}{
        width: 820px;
        margin-left: 12px;
    }
    @media ${device.mobile}{
        margin-left: 12px;
    }
`

const ImageText = styled.span`
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    color: ${props => props.theme.header.fontColor || 'white'};
    letter-spacing: -0.01em;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 100%;
    @media ${device.mobile}{
        font-size: 24px;
    }


`

const Titel = styled.span`
    color: ${props => props.theme.header.fontColor || 'white'};
    font-size: 28px;
    font-weight: 900;
    line-height: 125%;
    @media ${device.mobile}{
        font-size: 18px;
    }

`

const Customer = styled.span`
    color: ${props => props.theme.header.fontColor || 'white'};
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    opacity: 0.8;
    margin-bottom: 8px;
    @media ${device.mobile}{
        display: none;
    }

`