import {useState, useRef, useEffect} from 'react'
import styled from 'styled-components'
import {device} from "../media"

import { useTranslation } from 'react-i18next';

interface DescriptionProps {
    expanded?: boolean
}

interface Props {
    text: string
    halfsize?: boolean
    metadataLabel? : string
}

interface WrapperProps {
    halfsize?: boolean
}

const ExpandableText = ({text, halfsize, metadataLabel}: Props) => {
    const [expandedDescription, setExpandedDescription] = useState(false)
    const [maxDescriptionHeight, setMaxDescriptionHeight] = useState(0)
    const {t} = useTranslation()
    const descRef = useRef(null)

    useEffect(() => {
        if(descRef.current && descRef.current.clientHeight > maxDescriptionHeight){
            setMaxDescriptionHeight(descRef.current.clientHeight)
        }
    }, [maxDescriptionHeight])

    return (
        <Wrapper halfsize = {halfsize}>
            <TextWrapper>
            {
                metadataLabel &&
                <Label>
                    {metadataLabel}
                </Label>
            }
            {
                !!text && 
                <>
                    <Description  expanded={expandedDescription} dangerouslySetInnerHTML={{ __html: text }}/>
                    {
                        text && maxDescriptionHeight === 0 &&
                        <CalculationBox expanded={true} ref={descRef} dangerouslySetInnerHTML={{ __html: text }}/>
                    }
                    
                </>
            }
            </TextWrapper>
                {
                    text && maxDescriptionHeight > 99 && 
                    <ToggleExpand onClick={() => setExpandedDescription(!expandedDescription)}>
                        {
                            expandedDescription ?
                            t('less')
                            :
                            t('more')
                        }
                    </ToggleExpand>

                }
            
        </Wrapper>
    )
}

export default ExpandableText

const Wrapper = styled.div<WrapperProps>`
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    width: ${props => props.halfsize ? "222px" : "100%"};
    @media ${device.mobile}{
        width: ${props => props.halfsize ? "162px" : "100%"};
    }
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const Description = styled.p<DescriptionProps>`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_700};
    position : relative;
    max-height: ${props => props.expanded ? '100%' : '99px'}; 
    transition: max-height 0.5s ${props => props.expanded ? 'ease-in' : 'ease-out'}; 
    overflow: hidden;
    width: 100%;
    &:after {
        content  : "";
        position : absolute;
        z-index  : 1;
        bottom   : 0;
        left     : 0;
        pointer-events   : none;
        background-image : ${props => props.expanded ? 'none' : 'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)'};
        width    : 100%;
        height   : 2em;
    }

`

const CalculationBox = styled.p<DescriptionProps>`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_700};
    position : absolute;
    top: 0;
    left: 0;
    max-height: ${props => props.expanded ? '100%' : '99px'}; 
    transition: max-height 0.5s ${props => props.expanded ? 'ease-in' : 'ease-out'}; 
    overflow: hidden;
    visibility: hidden;
    width: 100%;
    &:after {
        content  : "";
        position : absolute;
        z-index  : 1;
        bottom   : 0;
        left     : 0;
        pointer-events   : none;
        background-image : ${props => props.expanded ? 'none' : 'linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%)'};
        width    : 100%;
        height   : 2em;
    }

`

const ToggleExpand = styled.span`
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    z-index: 2;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    align-self: flex-end;
    @media ${device.tablet}{
        margin-right: 12px;
    }
    &:hover {
        color: ${props => props.theme.color.primaryHover};
    }
`

const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 8px;
    
`