import { useEffect, useContext } from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import {Link} from 'react-router-dom'
import Card from '../components/Card'
import ContentBody from "../components/ContentBody"
import EmptyContent from '../components/EmptyContent';
import ContentHeadline from '../components/ContentHeadline'
import {device} from "../media"
import { useTracking } from '../hooks/useTracking';

const Favorites = ({favorites}) => {
    const {t} = useTranslation()
    const themeContext = useContext(ThemeContext);

    const {trackPageVisit} = useTracking()

    // Track page view
    useEffect(() => {
        trackPageVisit({documentTitle: `Favoriten`})
    }, [])

    const filterFavorites = (favorite) => {
        //For old favorites before language select: if no language is set for the favorite, check if the current language is the default language of the theme
        if(!favorite.forLanguage){
            if(i18next.language === themeContext.language){
                return true
            }
        }
        //if there is a language, check if its the currently chosen one
        if(favorite.forLanguage === i18next.language){
            return true
        }
        return false
    }

    return (
        <ContentBody>

            {
                favorites.filter(favorite => filterFavorites(favorite)).length > 0 ?
                <>
                    <ContentHeadline text={ t('favorites')}/>

                    <Content>
                          
                    {
                        favorites.filter(favorite => filterFavorites(favorite)).map((item,index) => (
                            <Card key={index} edition={item}/>
                        ))
                        
                    }
                    </Content>
                </>
                :
                <EmptyContent title={t('no_favorites_title')} description={t('no_favorites_description')} />
            }
           
        </ContentBody>
    )
}

const mapStateToProps = (state, props) => {
    return {
      favorites: state.favorites,
    }
}

export default connect(mapStateToProps)(Favorites)

const Content = styled.div`
  display: flex;
  margin: 0 -12px;
  margin-top: 4px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @media ${device.mobile}{
    margin: 0;
    }
`


