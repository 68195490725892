import styled from 'styled-components'
import SVG from "react-inlinesvg";

interface Props {
    icon?: any,
    title?: string,
    primary?: boolean,
    destructive?: boolean,
    tertiary?: boolean,
    inactive?: boolean
    active?: boolean,
    onClick: () => void,
    children?: any
}

const Button = ({children, title, onClick, icon, primary, destructive, tertiary, active, inactive}: Props) => {

    return (
        <ButtonElement primary={primary} destructive={destructive} tertiary={tertiary} active={active} inactive={inactive} onClick={inactive ? null : onClick}>
            { icon && 
                <StyledSVG src={icon}/>}
            {
                icon && title &&
                <Seperator/>
            }
            {title}
            {children}
        </ButtonElement>
    )
}

export default Button

const ButtonElement = styled.button<Props>`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    margin: 0 6px;
    height: 40px;
    box-shadow: ${props => props.tertiary ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.05)'};
    border-radius: 8px;
    color: ${props =>  props.primary || props.destructive ? '#FFFFFF' : props.theme.color.grey_900};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    border: ${props => props.tertiary ? 'none' : '1px solid' + props.theme.color.grey_200};
    background-color: ${props => (props.primary ? props.theme.color.primary : props.destructive ? props.theme.color.destructive : '#FFFFFF')};
    opacity: ${props => props.inactive ? 0.2 : 1};
    cursor: ${props => !props.inactive && 'pointer'};
    user-select: none;
    & path {
        stroke-width: ${props => (props.active ? 0 : 1.56)};
        fill: ${props => (props.active ? props.theme.color.primary : props.theme.color.grey_400)};
    }

    &:hover{
        background-color: ${props => (props.primary ? props.theme.color.primaryHover : props.destructive ? props.theme.color.destructiveHover : props.theme.color.grey_50)};
        box-shadow: ${props => props.tertiary ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)'};
    }


`

const StyledSVG = styled(SVG)`

`

const Seperator = styled.span`
    width: 8px;
`