import { useState, useEffect, useContext } from 'react';
import {connect} from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import Card from '../components/Card'
import ContentBody from "../components/ContentBody"
import {device} from "../media"
import { useTracking } from '../hooks/useTracking';
import { ThemeContext } from 'styled-components';

const KioskOverview = () => {
    const [kiosks, setKiosks] = useState([])
    const themeContext = useContext(ThemeContext);

    const {trackPageVisit} = useTracking()

    useEffect(() => {

        setKiosks([])

        let kioskConfigs = themeContext.kiosks;
        axios.all(kioskConfigs.map((kioskConfig) => axios.get(kioskConfig.url + '/backend/category/0'))).then(axios.spread((...response) => {

            let kiosksInfo = kioskConfigs.map((config, index) => 
                ({
                    // @ts-ignore
                    title: config.title, path: config.url, cover: config.url + '/' + response[index].data.categories[0].products[0].path + '/' + response[index].data.categories[0].products[0].cover
                }));

            setKiosks(kiosksInfo)

            // Track page view
            trackPageVisit({documentTitle: `Kiosk-Übersicht`})
        }))

    }, [])

    return (
        <ContentBody>
                 <Content> 
                 {
                     kiosks.map((item,index) => (
                         <Card key={index} edition={item}/>
                     ))
                     
                 }
                </Content>
        </ContentBody>
    )
}

const mapStateToProps = (state, props) => {
    return {
        kiosks: state.kiosks,
    }
}

export default connect(mapStateToProps)(KioskOverview)

const Content = styled.div`
  display: flex;
  margin: 0 -12px;
  margin-top: 4px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @media ${device.mobile}{
    margin: 0;
    }
`


