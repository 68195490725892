import { useContext } from 'react';
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import i18next from 'i18next';
import { ThemeContext } from 'styled-components';
import {connect} from 'react-redux'
import { deleteFavorite} from '../redux/actions/favoritesActions'

import Button from '../components/Button'

interface Props{
    title: string,
    description: string
    favorites: any
    deleteFavorite: any
}

const EmptyContent = ({title, description, favorites, deleteFavorite}: Props) => {
    const {t} = useTranslation()
    const history = useHistory()
    const themeContext = useContext(ThemeContext);

    const filterFavorites = (favorite) => {
        //For old favorites before language select: if no language is set for the favorite, check if the current language is the default language of the theme
        if(!favorite.forLanguage){
            if(i18next.language === themeContext.language){
                return true
            }
        }
        //if there is a language, check if its the currently chosen one
        if(favorite.forLanguage === i18next.language){
            return true
        }
        return false
    }

    const isIdinFavorites = (): boolean => {
        let found = false
        const locationId = history.location.pathname.split("/detail/")[1]
        favorites.filter(favorite => filterFavorites(favorite)).map((item,index) =>  {
            if(locationId === item.id){
                found = true
            }

        })
        return found
    }

    const handleDeleteFavorite = () => {
        deleteFavorite(history.location.pathname.split("/detail/")[1])
        history.push("/favorites")
    }

    return (
        <Wrapper>
            <Title>
                {isIdinFavorites() ? t('favourite_not_found'): title}
            </Title>
            <Description>
                {description}
            </Description>
            <ButtonWrapper>
                <Button primary onClick={() => history.push('/')} title={t('back_to_start')}/>
                {
                    isIdinFavorites() && 
                    <Button destructive onClick={() => handleDeleteFavorite()} title={t('remove_favorite')}/>
                }

            </ButtonWrapper>
        </Wrapper>
    )
}

const mapStateToProps = (state, props) => {
    return {
      favorites: state.favorites,
    }
}

const mapDispatchToProps = (dispatch) => ({
    deleteFavorite: (fav) => dispatch(deleteFavorite(fav)),
  })

export default connect(mapStateToProps, mapDispatchToProps)(EmptyContent)

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
`

const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 125%;
    max-width: 446px;
    text-align: center;
    margin-bottom: 12px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const Description = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    max-width: 446px;
    text-align: center;
    margin-bottom: 24px;
    color: ${props => props.theme.color.grey_500};
`

const ButtonWrapper = styled.div`
    display: flex;
`