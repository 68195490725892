import styled from 'styled-components'

const ContentHeadline = ({text}) => {
    return(
        <Headline>{text}</Headline>
    )
}

export default ContentHeadline

const Headline = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 100%;
  color: ${props => props.theme.color.grey_900};
  margin-right: 16px;
  margin-bottom: 32px;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.grey_100};;
  padding-bottom: 12px;
`