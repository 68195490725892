import axios from 'axios'
import convert from 'xml-js'

export const loadCategories = (categories) => ({
  type: 'LOAD_CATEGORIES',
  categories
})

export const fetchCategories = (backend, appName) => {
  return(dispatch) => {
    return axios.get(`${backend}${appName}categories`)
    .then((response) => {
      const updatedItems = []
      getCategoryData(response.data, null, updatedItems)
      dispatch(loadCategories(updatedItems))
    })
  }
}

export const loadCategoriesFromXML = () => {
  return (dispatch) => {
    return axios.get(process.env.PUBLIC_URL + '/channelindex.xml')
    //return axios.get('https://www.klett-kita.de/app-tps/ios/test/channelindex.xml')
    .then((response) => {
        try {
          const updatedItems = []
            const channelindex: any = convert.xml2js(response.data, {
              compact: true,
              alwaysArray: true
            })
            const categories = channelindex.data[0].categories[0].category


            getCategoryData(categories, null, updatedItems)
            
      
            dispatch(loadCategories(updatedItems))
          } catch (error) {
            console.log('Error loading xml in categories')
          }
    })
        

  }
}

const getCategoryData = (categories, parent, stack) => {
  categories.forEach(category => {
    stack.push({
      id: category.id,
      name: category.name,
      parent: parent,
      productType: category.productType
    })
    if(category.childCategories){
      getCategoryData(category.childCategories, category.id, stack)
    }
  });
}