import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import de from './translations/de.json'
import en from './translations/en.json'

let customGlobal = {}
let customDe = {}
let customEn = {}

try {
  customGlobal = require(`../theme/${process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME :  'p1dev'}/i18n.json`)
  customDe = require(`../theme/${process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME :  'p1dev'}/translations/de.json`) 
  customEn = require(`../theme/${process.env.REACT_APP_THEME ? process.env.REACT_APP_THEME :  'p1dev'}/translations/en.json`) 
 } catch(e) {

}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {...en, ...customGlobal, ...customEn}
      },
      de: {
        translation: {...de, ...customGlobal, ...customDe}
      }
    },
    lng: "de",
    fallbackLng: "de",
    debug: true,

    interpolation: {
      escapeValue: false
    }
  });

export default i18n