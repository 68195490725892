const size = {
  uhd: '1980px',
  widescreen: '1366px',
  desktop: '1024px',
  tablet: '834px',
  mobile: '768px',
}
export const device = {
  uhd: `(max-width: ${size.uhd})`,
  widescreen: `(max-width: ${size.widescreen})`,
  desktop: `(max-width: ${size.desktop})`,
  tablet: `(max-width: ${size.tablet})`,
  mobile: `(max-width: ${size.mobile})`,
}