import {useState, useEffect, useContext} from 'react'
import styled from 'styled-components'
import SVG from "react-inlinesvg";
import { useTranslation } from 'react-i18next';
import {device} from "../media"
import IconSearch from '../img/icons/icon_search.svg'
import IconSearchDelete from '../img/icons/icon_search_delete.svg'
import Thumbnail from '../reader/Thumbnail'
import { ThemeContext } from 'styled-components';
import { Trans } from 'react-i18next'

type InputProps = {
    searched?: boolean
}

const ReaderSearch = ({noResultText, currentEdition, setPopupActive, useExtendedSearch, kioskSearch, kioskSearchResults, onKioskSearchClick, keyword, setKeyword}) => {
    const {t} = useTranslation()
    const [searched, setSearched] = useState(false)
    const [searchResult, setSearchResult] = useState([])
    //const [kioskSearch, setKioskSearch] = useState({content: [], numberOfElements: 0})
    const themeContext = useContext(ThemeContext);
    const sendSearchMessage = (type: string, keyword: string) => {
        window.readerFrame.postMessage({type, keyword}, "*")
    }

    const sendPageMessage = (type: string, number: number) => {
        window.readerFrame.postMessage({type, number}, "*")
        setPopupActive(false)
    }

    useEffect(() => {
        function receiveMessage (event) {
            switch(event.data.type){
                case 'searchResult':
                    setSearchResult(event.data.result)
                    setSearched(true)
                    break
            }
        }

        window.addEventListener("message", receiveMessage)
        return () => {
            window.removeEventListener("message", receiveMessage)
        }
    }, [])

    const getSearchSubstring = (text) => {
        const keywordIndex = text.indexOf(keyword)
/*         const min = (keywordIndex - 50) < 0 ? 0 : keywordIndex - 25
        const max = (keywordIndex + 50) > keyword.length ? 0 : keywordIndex - 25 */

        const substring = "..." + text.substring(keywordIndex -50, keywordIndex + 50) + "..."
        return substring

    }

    const handleSearch = (e) => {
        e.preventDefault()
        if(useExtendedSearch){
            setSearched(false)
            kioskSearch(keyword)
            setSearched(true)
        } else {
            
            setSearched(false)
            sendSearchMessage('search', keyword)
        }

    }

    const onResultClick = (id, index) => {
        sendPageMessage('goToPage', id)
        onKioskSearchClick(index)
    }

    return (
        <Wrapper>
            <SearchWrapper searched={searchResult.length > 0 || kioskSearchResults.numberOfElements > 0}>
                <SearchIcons>
                    <IconLeft src={IconSearch}/>
                    {
                        keyword &&
                        <IconRight onClick={() => setKeyword("")} src={IconSearchDelete}/>
                    }
                    
                </SearchIcons>
                <SearchForm onSubmit={(e) => handleSearch(e)}>
                    <SearchInput  autoFocus placeholder={t('searchterm')} value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                </SearchForm>
            </SearchWrapper>
            
            <ResultList>
                {
                    !useExtendedSearch ?
                    <>
                        {
                            searched && searchResult.length === 0 ?
                            <EmptyText>
                                {noResultText.replace('[SEARCHTERM]', keyword)}
                            </EmptyText> 
                            :
                            <>

                                    <ResultText>
                                        <Trans i18nKey='hits_on_pages' values={{ number: searchResult.length }}>
                                            Insgesamt <strong>42</strong> Suchergebnisse
                                        </Trans>
                                    </ResultText>
                                
                                {
                                    searchResult.map((result, index) => (
                                        <ResultItem key={index} onClick={() => sendPageMessage('goToPage', result.pageid - 1)}>
                                            <Thumbnail src={`${currentEdition}/preview/thumb/${result.pageid}.jpg`} alt={`Seite ${result.pagenumber}`}/>
                                            <Text dangerouslySetInnerHTML={{__html: getSearchSubstring(result.content).replace(new RegExp(keyword, "ig"), `<span style="color: ${themeContext.color.primary}">${keyword}</span>`)}}/>
                                            <PageNumber>{result.pagenumber}</PageNumber>
                                        </ResultItem>
                                        
                                    ))
                                }
                            </>


                        }
                    </>
                    :
                    <>
                        {
                            searched && kioskSearchResults.numberOfElements === 0 ?
                            <EmptyText>
                                {noResultText.replace('[SEARCHTERM]', keyword)}
                            </EmptyText> 
                            :
                            <>

                                    <ResultText>
                                        <Trans i18nKey='hits_on_pages' values={{ number: kioskSearchResults.numberOfElements }}>
                                            Insgesamt <strong>42</strong> Suchergebnisse
                                        </Trans>
                                    </ResultText>
                                
                                {
                                    kioskSearchResults.content.map((result, index) => (

                                        <ResultItem key={index} onClick={() => onResultClick(result.itemId, index)}>
                                            <Thumbnail src={`${currentEdition}/preview/thumb/${result.itemId + 1}.jpg`} alt={`Seite ${result.title}`}/>
                                            <Text dangerouslySetInnerHTML={{__html: "..." + result.description.replace(new RegExp(keyword, "ig"), `<span style="color: ${themeContext.color.primary}">${keyword}</span>`) + "..."}}/>
                                            <PageNumber>{result.title}</PageNumber>
                                        </ResultItem>
                                        
                                    ))
                                }
                            </>
                            

                        }
                    </>
                }

                </ResultList>
        </Wrapper>
    )
}

export default ReaderSearch

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 350px;
    @media ${device.mobile}{
        width: 100%;
    }
`

const ResultItem = styled.div`
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.color.grey_100};
    }
`

const Text = styled.span`
    margin: 0 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const PageNumber = styled.span`
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const ResultList = styled.div`
    overflow-y: auto;
`

const SearchWrapper = styled.div<InputProps>`
    position: relative;
    border: 1px solid ${props => props.theme.color.grey_200};
    box-sizing: border-box;
    /* Shadow/XS */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: flex;
    height: 38px;
    width: 100%;
    margin-bottom: ${props => props.searched ? '12px' : '0px'};
    &:focus-within {
        border: 1px solid ${props => props.theme.color.primary};
    }
`

const SearchIcons = styled.div`
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    pointer-events: none;
`


const IconRight = styled(SVG)`
    margin-right: 20px;
    cursor: pointer;
    pointer-events: all;
`
const IconLeft = styled(SVG)`
    margin-left: 12px;
`

const SearchForm = styled.form`
    flex: 1;
    display: flex; 
    align-items: center;
    height: 34px;
`

const SearchInput = styled.input`
    width: 100%;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    margin-left: 34px;
    padding-right: 44px;
    color: ${props => props.theme.color.grey_900};
    &:focus {
        outline: none;
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }
    :-ms-input-placeholder {
        color: ${props => props.theme.color.grey_400};
    }
`

const EmptyText = styled.span`
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_600};
`

const ResultText = styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500}
`