import styled from 'styled-components'
import {device} from "../media"
interface Props {
    label: string,
    data: any
}

const MetaDataItem = ({label, data}: Props) => {
    return (
        <>
        {
            data &&
            <Wrapper>
                <Label>
                    {label}
                </Label>
                <Data>
                    {data}
                </Data>
            </Wrapper>
        }
        
        </>
    )
}

export default MetaDataItem

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 222px;
    margin-bottom: 32px;
    @media ${device.mobile}{
        width: 162px;
    }
`

const Label = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 8px;
    
`

const Data = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    color: ${props => props.theme.color.grey_900};
`