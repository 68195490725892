import axios from 'axios'
import convert from 'xml-js'
import moment from 'moment-with-locales-es6'

export const loadEditions = (editions) => ({
  type: 'LOAD_EDITIONS',
  editions
})

export const setCurrent = (current) => ({
  type: 'CURRENT',
  current
})

export const loadEditionsFromXML = () => {
  return (dispatch) => {
    return axios.get(process.env.PUBLIC_URL + '/channelindex.xml')
    //return axios.get('https://www.klett-kita.de/app-tps/ios/test/channelindex.xml')
    .then((response) => {
        try {
            moment.locale('de')
            const updatedItems: any = []
            const channelindex: any = convert.xml2js(response.data, {
              compact: true,
              alwaysArray: true
            })
            const editionsPath = channelindex.data[0].editions[0]._attributes.path
            const editions = channelindex.data[0].editions[0].edition
            editions.sort(function(a, b) {
              return b.valid_from[0]._text - a.valid_from[0]._text
            })
            editions.forEach(edition => {
              const editionPath = edition._attributes.path
              const coverPath = `${editionsPath}/${editionPath}/${edition.cover[0]._text}`
              const validFrom = moment(edition.valid_from[0]._text, 'YYYYMMDD')
              const previews = []
              if(edition.previews && edition.previews[0].image) {
                edition.previews[0].image.forEach((image) => {
                  previews.push(`${editionsPath}/${editionPath}/${image._text[0]}`)
                })
              }
              const categories = []
              if(edition.categories && edition.categories[0].category){
                edition.categories[0].category.forEach((category) => {
                  categories.push(category._text[0])
                })
              }
              const size = edition.download[0].size[0]._text[0]
              const editionNumber = edition.edition_number[0]._text[0]
              const pages = edition.page_count[0]._text[0]
              
              const isbn = edition.isbn && edition.isbn[0]._text ? edition.isbn[0]._text : ''
              const issn = edition.issn && edition.issn[0]._text ? edition.issn[0]._text : ''
              const ean = edition.ean && edition.ean[0]._text ? edition.ean[0]._text : ''
              const copyright = edition.copyright && edition.copyright[0]._text ? edition.copyright[0]._text : ''
              const orderingNumber = edition.ordering_number && edition.ordering_number[0]._text ? edition.ordering_number[0]._text : ''
              const publisher = edition.publisher && edition.publisher[0]._text ? edition.publisher[0]._text : ''
              updatedItems.push({
                editionspath: editionsPath,
                id: edition._attributes.id,
                path: `${editionPath}/`,
                cover: coverPath,
                title: edition.title[0]._cdata,
                subtitle: edition.subtitle[0]._cdata,
                description: edition.description[0]._cdata,
                previews: previews,
                categories: categories,
                metadata: {
                  validFrom: validFrom.format('DD. MMMM YYYY'),
                  pages: `${pages} Seite${pages === 1 ? 'n' : ''}`,
                  size: `${size} MB`,
                  editionNumber: editionNumber,
                  isbn: isbn,
                  issn: issn,
                  ean: ean,
                  copyright: copyright,
                  orderingNumber: orderingNumber,
                  publisher: publisher
                }
              })
            })
      
            dispatch(loadEditions(updatedItems))
          } catch (error) {
            console.log(error)
          }
    })
        

  }
}