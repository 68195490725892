const defaultState = {section: 0}
const userReducer = (state = defaultState, action) => {
    switch (action.type) {
      case 'CURRENT_SECTION':
        return {...state, section: action.section}
      default:
        return state
    }
  }
  
  export default userReducer