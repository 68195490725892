import logo from './logo.png'
import newsImage from './newsImage.png'


export const theme = {
    color: {
      primary: "#26b4af",
      primaryHover: "#22a29d",
    },
    logoImage: logo,
    backend: "https://unterrichtsprogramm.wertvoll-macht-schule.de/",
    directory: "/kiosk",
    header: {
      customer: "",
      title: "Unterrichtsprogramm",
      lineHeader: true,
    },
    info: [
      {
        text: 'Unsere Vision',
        link: 'https://www.wertvoll-macht-schule.de/'
      },
      {
        text: 'Jetzt spenden!',
        link: 'http://spenden.wertvoll-macht-schule.de/'
      },
      {
        text: 'Impressum',
        link: 'https://www.wertvoll-macht-schule.de/impressum/'
      },
      {
        text: 'Datenschutz',
        link: 'https://www.wertvoll-macht-schule.de/haftungsausschluss-und-datenschutz/'
      },
      {
        text: 'Nutzungsbedingungen',
        link: 'https://www.wertvoll-macht-schule.de/nutzungsbedingungen/'
      }
  ],
  app: {
    title: "WERTvoll"
  },
    newsSlider: {
      image: newsImage,
    },
    login: {
      type: "internal",
      subscriptionTitle: "Anmeldung",
      subscriptionText: "Du bist noch nicht als Lehrer*in bei WERTvoll macht Schule angemeldet?",
      resetEmail: "sekretariat@wertvoll-macht-schule.de",
      links: [{
        id: 1,
        text: 'Jetzt anmelden!',
        url: "https://www.wertvoll-macht-schule.de/anmeldung/"
      }
      ]
    }
  };