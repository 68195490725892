import styled from 'styled-components'
import {device} from "../media"
type Props = {
    children?: any,
    active?: boolean,
    center?: boolean
}
const PopupMenu = ({children, active, center}: Props) => {
    return (
        <>
            { active &&
                <Wrapper center={center}>
                    {children}
                </Wrapper>
            }
        </>
    )
}

export default PopupMenu

const Wrapper = styled.div<Props>`
    position: absolute;
    bottom: 64px;
    left: ${props => props.center ? '50%' : '0px'};
    transform: ${props => props.center ? 'translateX(-50%)' : ''};
    min-width: 225px;
    background-color: white;
    padding: 12px;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    /* Shadow / XL */
    max-width: 800px;
    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-height: 500px;
    @media ${device.mobile}{
        max-width: 92%;
        left: 12px;
    }

`