import styled from 'styled-components'
import SVG from "react-inlinesvg";

import IconChevronLeft from '../img/icons/icon_chevron_left.svg'
import {device} from "../media"
interface Props {
    onClick: () => void,
    label: string
}

const Breadcrumbs = ({onClick, label}: Props) => {

    return (
        <Wrapper onClick={onClick}>
            <TapArea>
                <IconWrapper>
                    <Icon src={IconChevronLeft}/>
                </IconWrapper>

                <Label>{label}</Label>
            </TapArea>
        </Wrapper>
    )
}

export default Breadcrumbs

const Wrapper = styled.div`
    width: 100%;
    height: 33px;
    border-bottom: 1px solid ${props => props.theme.color.grey_100};
    margin-bottom: 32px;

    @media ${device.mobile}{
        border-bottom: none;
        height: 20px;
        margin-bottom: 24px;
    }

`

const TapArea = styled.div`
display: flex;
align-items: center;
    cursor: pointer;
    color: ${props => props.theme.color.grey_600};
    & path {
    fill: ${props => props.theme.color.grey_600};
    }
    &:hover {
        color: ${props => props.theme.color.grey_900};
        & path {
        fill: ${props => props.theme.color.grey_900};

        }
    }
`

const Label = styled.label`
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
`

const Icon = styled(SVG)`
    height: 20px;
    width: 20px;
`

const IconWrapper = styled.span`
    display: flex;
    align-items: center;
    padding: 1px;
    border: 1px solid ${props => props.theme.color.grey_200};;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    margin-right: 12px;
`
