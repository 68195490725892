import SVG from "react-inlinesvg";


import styled from 'styled-components'
import IconClose from '../img/icons/icon_close.svg'
import {device} from "../media"

type Props = {
    active: boolean,
    close: any,
    title: string,
    description: string,
    children?: any
    link?: {
        text: string,
        url: string
    }
}


const LoginModal = ({active, close, title, description, link, children}: Props) => {
    return (
        <>
            {
                active &&
                <Modal>
                <Wrapper>
                    <Close src={IconClose} onClick={close}/>
                    <Title>{title}</Title>
                    <Description>{description}</Description>
                    {
                        link && link.url !== "" &&
                        <Link target="_blank" href={link.url}>{link.text}</Link>
                    }
                    {children}
                </Wrapper>

            </Modal>
            }
        </>

    )
}

export default LoginModal

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 23, 42, 0.24);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

`

const Close = styled(SVG)`
    position: absolute;
    top: 28px;
    right: 28px;
    height: 12px;
    width: 12px;
    cursor: pointer;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }
`

const Wrapper = styled.div`
    width: 400px; 
    border: 1px solid #E2E8F0;
    /* Shadow / XL */

    box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.04), 0px 20px 25px -5px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 32px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    position: relative;
    max-height: 70%;
    overflow-y: auto;
    @media ${device.mobile}{
        margin: 24px;
        margin-bottom: 50px;
    }
    
`

const Title = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const Description = styled.span`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.color.grey_500};
    margin-top: 16px;
`

const Link = styled.a`
    color: ${props => props.theme.color.primary};
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    &:hover {
        cursor: pointer;
    }
`