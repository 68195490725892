export const getEditionSubModel = (edition) => {
    var free = 'free'

    var paid = convertPrice(edition.price)

    if(edition.subscriptions.length === 0 && paid !== "0"){
        free = 'unavailable'
        return free
    }
    if(!edition.public)  {
        return 'paid'
    }
    if(edition.subscriptions.length === 0){
      free = 'free'
      return free
    }
    if(paid !== "0"){
      free = 'paid'
    }
    return free
}

export const convertPrice = (price) => {
    switch(price){
        case "0,0":
            return "0"
        case "0":
            return "0"
        case "0,00":
            return "0"
        default:
            return price
    }
}