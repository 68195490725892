import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import CategoryListEntry from '../components/CategoryListEntry'
import {device} from "../media"

const CategoryList = ({categories, currentSection}) => {
    const {t} = useTranslation()

    return (
        <Wrapper>
            <Label>
                {t('categories').toUpperCase()}
            </Label>
            {
                categories.filter(category => currentSection === "0" ? category.parent === null : category.parent === currentSection).map((category,index) => (
                    <CategoryListEntry 
                        key={index}
                        id={category.id}
                        name={category.name}
                        categories={categories}/>
                ))
            }
            
        </Wrapper>
    )
}

export default CategoryList

const Wrapper = styled.div`
display: flex;
flex-direction: column;
    box-sizing: border-box;
    width: 222px;
    padding-right: 10px;
    margin-top: 32px;
    @media ${device.mobile}{
        display: none;
    }
`

const Label = styled.label`
    box-sizing: border-box;
    width: 67px;
    height: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_500};
    margin-bottom: 10px;
    margin-left: 10px;
    letter-spacing: 0.04em;
`