import React, {useState, useEffect, useRef} from 'react'
import styled from 'styled-components'
import {device} from "../media"
import PopupMenu from '../reader/PopupMenu'
import ToolbarButton from './ToolbarButton';
import IconMinus from '../img/icons/icon_minus.svg'
import IconPlus from '../img/icons/icon_plus.svg'
import Button from '../components/Button';

const PageNumbers = ({title, numbers}) => {
    const [popupActive, setPopupActive] = useState(false)
    const [chooserValue, setChooserValue] = useState("1")
    const pageNumberRef = useRef(null)
    const handlePopupActive = () => {
        setPopupActive(!popupActive)
        setChooserValue(numbers.length > 1 ? numbers[1] : numbers[0])
    }

    const sendPostMessage = (type: string, number: string) => {
        window.readerFrame.postMessage({type, number}, "*")
        handlePopupActive()
    }

    const handleSubmit = (e) => {        
        e.preventDefault()
        sendPostMessage('gotoPageByNumber', chooserValue.toString())
    }

    const setPageFromInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const pageNumber = e.target.value
        if (!Number.isNaN(pageNumber)) {
            setChooserValue(pageNumber)
        } else {
            setChooserValue(null)
        }
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (pageNumberRef && pageNumberRef.current && !pageNumberRef.current.contains(event.target)) {

                    
                    // @ts-ignore
                    setPopupActive(false)


                
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Wrapper ref={pageNumberRef}>
            <ClickArea onClick={handlePopupActive}>

            
            {numbers[0]}
            {
                numbers.length > 1 && 
                <>
                    <Seperator/>
                    {numbers[1]}
                </>
            }
            </ClickArea>
            <PopupMenu active={popupActive} center>
                <PopupTitle>
                    {title}
                </PopupTitle>
                <NumberChooser>
                    <ToolbarButton src={IconMinus} onClick={() => setChooserValue((Number.parseInt(chooserValue) - 1).toString())}/>
                    <Form onSubmit={handleSubmit}>
                        <Display value={chooserValue} onChange={setPageFromInput} />
                    </Form>
                    
                    <ToolbarButton src={IconPlus} onClick={() => setChooserValue((Number.parseInt(chooserValue) + 1).toString())}/>
                </NumberChooser>
                <Button primary inactive={chooserValue === null} onClick={() => sendPostMessage('gotoPageByNumber', chooserValue.toString())}>{title}</Button>
            </PopupMenu>
        </Wrapper>
    )
}

export default PageNumbers

const Wrapper = styled.div`
    background: white;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: ${props => '1px solid ' + props.theme.color.grey_200};

    margin: 0 16px;
    @media ${device.mobile}{
        margin: 0 8px;
    }
`

const ClickArea = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 20px;
    cursor: pointer;
`

const Seperator = styled.span`
    height: 18px;
    width: 1px;
    background: ${props => props.theme.color.grey_400};
    margin: 0px 10px;
`

const PopupTitle = styled.span`
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: ${props => props.theme.color.grey_900};
    margin-bottom: 16px;
`

const NumberChooser = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
`

const Display = styled.input`
    border-radius: 8px;
    border: ${props => '1px solid ' + props.theme.color.grey_200};
    text-align: center;
    width: 50px;
    height: 40px;
    margin-top: -4px;
`

const Form = styled.form`
    
`