import Slider from 'rc-slider';
import styled from 'styled-components'
import 'rc-slider/assets/index.css';
const CustomSlider = ({min, max, step, value, onChange, themeContext}) => {
    const handle = props => {
        const { value, dragging, index, ...restProps } = props;
            return (
                <CustomHandle value={value} {...restProps} />
            );
    };
    return (
        <Wrapper>
            <Slider handle={handle} min={min} max={max} step={step} value={value} onChange={onChange} trackStyle={{backgroundColor: themeContext.color.primary, height: '2px'}} railStyle={{height: '2px'}}/>
        </Wrapper>
        
    )
}

export default CustomSlider

const { Handle } = Slider;

const Wrapper = styled.div`
    margin: 0 8px;
`

const CustomHandle = styled(Handle)`
    width: 16px;
    height: 16px;
    border: 2px solid ${props => props.theme.color.grey_400} !important;
    
    margin-top: -6px;
    &:active {
        box-shadow: 0 0 5px ${props => props.theme.color.grey_500};
        border: 2px solid ${props => props.theme.color.grey_500} !important;
    }
    &:hover {
        border: 2px solid ${props => props.theme.color.grey_500} !important;
    }
`