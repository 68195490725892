import styled from 'styled-components'
import {device} from "../media"

const ContentBody = styled.div`
    width: 100%;
    margin-left: 32px;
    margin-bottom: 32px;
    @media ${device.mobile}{
        margin: 0;
        margin-bottom: 79px;
    }
`

export default ContentBody