import {useState, useEffect} from 'react'
import styled from 'styled-components'
import SVG from "react-inlinesvg";
import {device} from "../media"
import IconDot from '../img/icons/icon_dot.svg'
import IconChevronRight from '../img/icons/icon_chevron_right.svg'
import IconChevronDown from '../img/icons/icon_chevron_down.svg'

type ItemProps = {
    layer: any
}

type IconProps = {
    clickable: boolean
}

const ContentsTable = ({sitemap, setPopupActive}) => {
    const [expanded, setExpanded] = useState([])
    const sendPageMessage = (type: string, number: number) => {
        window.readerFrame.postMessage({type, number}, "*")
        setPopupActive(false)
    }

    useEffect(() => {
        if(sitemap.expanded){
            const list = []
            sitemap.content.map(entry => {
                if(entry.hasChildren){
                    list.push(entry.title)
                }
                return []
            })
            setExpanded(list)
        }
    }, [])

    const chooseIcon = (entry) => {
        if(!entry.hasChildren){
            return IconDot
        }
        if(expanded.includes(entry.title)){
            return IconChevronDown
        } 
        return IconChevronRight
    }

    const handleIconClick = (entry) => {
        if(!entry.hasChildren){
            return
        }
        if(expanded.includes(entry.title)){
            const copy = expanded.slice(0)
            copy.splice(copy.indexOf(entry.title), 1)
            setExpanded(copy)
        } else {
            const copy = expanded.slice(0)
            copy.push(entry.title)
            setExpanded(copy)
        }
    }

    return (
        <>  
        <Wrapper>
            {
                sitemap.content.map((entry, index) => (
                    <>
                    {
                        (!entry.parentTitle || expanded.includes(entry.parentTitle)) &&
                        <Item key={index} layer={entry.layer}>
                            <IconWrapper>
                                <Icon src={chooseIcon(entry)} onClick={() => handleIconClick(entry)} clickable={entry.hasChildren}/>
                            </IconWrapper>
                            <ClickArea onClick={() => sendPageMessage('goToPage', parseInt(entry.pageId))}>
                                <Text>
                                    {entry.title}
                                </Text>
                                <PageNumber>
                                    {entry.pageNumber}
                                </PageNumber>
                            </ClickArea>
                        </Item>
                    }
                    </>
                ))
            }
        </Wrapper>
        </>
    )
}

export default ContentsTable

const Wrapper = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    width: 350px;
    @media ${device.mobile}{
        width: 100%;
    }
    
`


const Item = styled.div<ItemProps>`
    display: flex;
    border-bottom: 1px solid ${props => props.theme.color.grey_100};
    align-items: center;
    margin-left: ${props => props.layer * 32}px;
    height: 100%;

`

const ClickArea = styled.div`
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.color.grey_100};
    }
    display: flex;
    padding: 12px;
    flex: 1;
    border-radius: 8px;
    margin-right: 8px;
`

const Text = styled.span`
    flex: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    color: ${props => props.theme.color.grey_900};
`

const PageNumber = styled.span`
    margin-left: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: ${props => props.theme.color.grey_600};
`

const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 34px;
`

const Icon = styled(SVG)<IconProps>`
    cursor:  'pointer';
    border-radius: 8px;
    padding: 4px;
    margin: 0 8px;
    flex-shrink: 0;
    & path {
        fill: ${props => props.theme.color.grey_500};
    }

    &:hover{
        background-color: ${props => props.clickable && props.theme.color.grey_100};
        cursor: ${props => props.clickable && 'pointer'};
    }
`