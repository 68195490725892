import styled from 'styled-components'
import Card from '../components/Card'
import {device} from "../media"
import Pagination from './Pagination';

const EditionListFull = ({editions, category, pages, currentPage, setCurrentPage}) => {

  return (
      <Wrapper>
        <Head>
          <Headline>
            {category}
          </Headline>
        </Head>
        <Content>
          {
            editions.map((item, index) => (
              <Card key={index} edition={item}/>
            ))
            
          }
          {
            pages > 1 &&
            <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
          }
          
          </Content>
      </Wrapper>
  )
}

export default EditionListFull

const Wrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-width: 100%;
    margin-bottom: 44px;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

`

const Content = styled.div`
  display: flex;
  margin: 0 -12px;
  margin-top: 4px;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @media ${device.mobile}{
        margin: 0;
    }
`

const Headline = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 125%;
  color: ${props => props.theme.color.grey_900};
  margin-right: 16px;
`